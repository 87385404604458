import React from "react";
import './style.css';
import {Link} from 'react-router-dom';
import { MdOutlineMail , MdCall} from "react-icons/md" ;
import { MdLocationOn } from "react-icons/md";
import { FaFacebook,FaLinkedin,FaInstagram } from "react-icons/fa";
import {  BsTwitterX } from "react-icons/bs";

// import { BsDisplay } from "react-icons/bs";

function Footer() {
    const scrollToTop = () => {
        window.scrollTo(0, 0);
      };

    return(
<>
<footer class='footer-portion'>

  <div class='footer-link'>
    
    
    <div className="footer-left">
     <h4>Useful Links</h4>
     <ul class='useful-link'>
     <li><Link to="/" className="footers-link" onClick={scrollToTop}>Home</Link></li>
     <li><Link to="/aboutus" className="footers-link" onClick={scrollToTop}>About us</Link></li>
      <li><a href="/#Benefits" className="footers-link" >Benefits</a></li>
      <li><a href="/#Feature" className="footers-link">Features</a></li>
       
      <li><a href="/#Client" className="footers-link" >Our Client</a></li>
   
      <li><Link to="/blog" className="footers-link" onClick={scrollToTop}>Blog</Link></li>
      <li><Link to="/contactus" className="footers-link" onClick={scrollToTop}>Contact us</Link></li>   
     </ul>
    </div>

  

     <div className="footer-right">
      <h4>Contact Us</h4>
       <ul class='useful-link'>
       <div style={{display:'flex', gap:'10px'}}><MdCall class='headericons'  style={{height:'28px',width:'28px'}}/> <li><a href="tel:8800228539" className="linkage">+91 8800228539</a> </li></div>
       <div style={{display:'flex', gap:'10px'}}><MdOutlineMail class='headericons'  style={{height:'28px',width:'28px'}}/><a href="mailto:sales@macotech.in" className="linkage"><li>sales@macotech.in</li></a></div>     

       <div style={{display:'flex', gap:'10px'}}>
       <MdLocationOn class='headericons'  style={{height:'30px',width:'30px', marginTop:'2px'}} />
       <li class='address' style={{marginTop:'0px'}}><a href="https://maps.app.goo.gl/cAkcLzHz6XFavKaK7" className="linkage">
       Bunglow Plot No. 2, First Floor West Patel Nagar, Opp. Metro Pillar No.-229 New Delhi - 110008.</a></li>
       </div>
      </ul>
      <div className="footer-social-link">
      <h3>Follow us :</h3>
      <div>
      <a href="https://www.facebook.com/macotechsoftware/" target="_blank" rel="noreferrer"><FaFacebook class='footericons-right'/></a>
  <a href="https://www.x.com/MacoTechLtd" target="_blank" rel="noreferrer"><BsTwitterX class='footericons-right'/></a>
  <a href="https://www.linkedin.com/company/macotechsoftware/" target="_blank" rel="noreferrer" ><FaLinkedin class='footericons-right'/></a>
  <a href="https://www.instagram.com/macotechsoftware/" target="_blank" rel="noreferrer"><FaInstagram class='footericons-right'/></a>
  </div></div>
     </div>



 </div>

  

 <h3>&copy; 2024 MacoTech Software Pvt. Ltd. All rights reserved. </h3>

  

</footer>
</>

    );
}

export default Footer ;